@media (min-width: 0px) {
  // MARK: Constant
  $pagePaddingX: 5vw;

  $headerHeight: 80px;
  $headerNavWidth: 300px;

  $sectionPaddingY: 64px;
  $sectionGap: 72px;

  // MARK: General
  ul {
    padding-inline-start: 40px;
  }

  // MARK: Language Switch Button {
  #languageSwitchButton {
    gap: 12px;

    .buttonWrapper {
      gap: 12px;

      button {
        font-size: 20px;
      }
    }

    .divider {
      height: 20px;
    }
  }

  // MARK: Header
  header {
    height: $headerHeight;
    padding: 0 $pagePaddingX;

    .leading {
      button {
        img {
          height: 24px;
        }
        svg {
          height: 24px;
          width: 24px;
        }
      }
    }

    aside {
      position: fixed;
      top: 0;
      left: 0;

      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.4);

      display: flex;
      flex-direction: column;

      .content {
        width: 100%;
        height: min-content;
        background-color: white;

        display: flex;
        flex-direction: column;
        align-items: flex-end;

        padding: 24px 24px 32px 24px;
        gap: 12px;

        button {
          min-width: 0;

          svg {
            color: black;

            height: 24px;
            width: 24px;
          }
        }

        nav {
          width: 100%;

          display: flex;
          flex-direction: column;
          gap: 12px;

          .pageList {
            width: 100%;

            display: flex;
            flex-direction: column;
            gap: 8px;

            padding: 12px 0;
            border-bottom: 1px solid #d9d9d9;

            .mainPage {
              padding: 0 8px;

              color: black;
              font-family: Inter;
              font-size: 24px;
              font-weight: 700;
            }

            .subPages {
              padding-left: 24px;

              button {
                width: 100%;
                padding: 12px 0px;

                display: flex;
                justify-content: space-between;

                p {
                  color: black;

                  font-family: Inter;
                  font-size: 18px;
                  font-weight: 600;
                }

                svg {
                  color: #939393;
                  font-size: 18px;
                }
              }
            }
          }
        }
      }

      .backdrop {
        flex: 1;
        width: 100%;
      }
    }
  }

  // MARK: Footer
  footer {
    gap: 24px;
    padding: 48px $pagePaddingX 56px $pagePaddingX;

    img {
      height: 20px;
    }

    section {
      gap: 16px;

      article {
        gap: 4px;

        p {
          font-size: 16px;

          &#copyright {
            font-size: 14px;
          }
        }
      }
    }
  }

  // MARK: PageTitle
  #pageTitle {
    padding: calc(1.2 * $sectionPaddingY) 0px calc(1.3 * $sectionPaddingY) 0px;
    gap: calc(0.75 * $sectionGap);

    p {
      font-size: 32px;
    }

    #divider {
      width: 80px;
    }
  }

  // MARK: ViewMore
  .viewMore {
    gap: 4px;

    svg {
      width: 16px;
      height: 16px;
    }

    p {
      font-size: 14px;
    }
  }

  // MARK: Scroll To Top
  button#scrollToTop {
    bottom: 32px;
    right: 24px;

    width: 56px;
    height: 56px;

    svg {
      width: 32px;
    }
  }

  // MARK: Main Page
  .page#main {
    #mainBanner {
      #navigator {
        bottom: 16px;
        right: $pagePaddingX;

        height: 36px;
        padding: 0 8px;
        gap: 4px;

        button {
          padding: 4px 8px;
        }
        p {
          font-size: 15px;
        }
      }
    }

    main section {
      .sectionTitle {
        font-size: 24px;
      }

      &#landing {
        height: 580px;
        background-position: left;

        article {
          gap: 40px;
          padding: 0 $pagePaddingX;

          div {
            gap: 16px;

            p {
              font-size: 22px;
            }

            #divider {
              width: 120px;
            }
          }

          .headline {
            font-size: 52px;
          }

          .description {
            font-size: 16px;
          }
        }

        .scrollDown {
          bottom: 4px;

          &:hover {
            @include spring-pop($translate: 10px);
            transform: translateY(10px);
          }

          gap: 12px;
          padding: 4px;

          img {
            width: 24px;
          }

          p {
            font-size: 20px;
          }
        }
      }

      &#steps {
        padding: $sectionPaddingY $pagePaddingX;
      }

      &#productLineUp {
        padding: $sectionPaddingY $pagePaddingX;
        gap: $sectionGap;

        article {
          gap: 48px 64px;

          .productCard {
            width: 280px;
            gap: 20px;

            img {
              &.logo {
                height: 32px;
              }
            }

            .thumbnail {
              p {
                font-size: 16px;
              }
            }
          }

          button {
            @include button-capsule($width: 178px, $height: 54px);
          }
        }
      }

      &#solution {
        gap: $sectionGap;
        padding: $sectionPaddingY $pagePaddingX;

        article {
          min-height: 480px;

          background-size: auto 280px;

          padding-bottom: 168px;

          > div {
            gap: 30px;

            padding-right: 16px;
            backdrop-filter: blur(5px);

            p {
              font-size: 16px;

              &.headline {
                font-size: 32px;
              }
            }

            .viewPDF {
              margin-top: 4px;
              gap: 4px;

              p {
                font-size: 16px;
              }

              svg {
                width: 16px;
              }
            }
          }
        }
      }

      &#technology {
        padding: $sectionPaddingY $pagePaddingX;
        gap: 72px 80px;

        .card {
          width: 280px;
          height: 380px;

          border-radius: 10px;

          &.tech {
            .front {
              gap: 16px;
              padding: 28px 32px;

              p {
                font-size: 26px;
              }

              .circle {
                min-width: 20px;
                min-height: 20px;
              }
            }

            .back {
              padding: 40px;
              gap: 28px;

              p {
                &:nth-of-type(1) {
                  font-size: 24px;
                }

                &:nth-of-type(2) {
                  font-size: 16px;
                }
              }

              .divider {
                width: 80px;
              }
            }
          }
        }
      }

      &#news {
        padding: $sectionPaddingY $pagePaddingX;
        gap: $sectionGap;

        article {
          gap: 44px;
          padding: 50px 20px 0 20px;

          border-radius: 30px;

          .swiper {
            height: 256px;

            --swiper-navigation-size: 16px;
            --swiper-pagination-top: 24px;

            .swiper-button-prev,
            .swiper-button-next {
              margin: 0 0px;
            }
          }

          .newsSlide {
            gap: 12px;
            padding: 20px 32px;

            p {
              &.title {
                font-size: 16px;
              }

              &.caption {
                display: none;
                font-size: 16px;
              }
            }
          }
        }
      }

      &#contactUs {
        padding: $sectionPaddingY $pagePaddingX;
        gap: $sectionGap;

        article {
          height: auto;
          flex-direction: column;
          gap: 56px;

          div {
            gap: 24px;

            p {
              font-size: 32px;
            }

            a {
              @include button-capsule($width: 186px, $height: 56px);
            }
          }

          iframe,
          video {
            height: auto;
            width: 100%;
          }
        }
      }

      &#partners {
        padding: $sectionPaddingY $pagePaddingX;
        gap: $sectionGap;

        article {
          &.window {
            @include infinite-looper($contentGap: 44px, $duration: 40s);
          }
        }
      }
    }
  }

  // MARK: Product Common
  .productCommon {
    &#banner {
      height: 360px;
      gap: 32px;
      padding: 0 $pagePaddingX;

      p {
        font-size: 24px;
      }

      a {
        @include button-capsule($width: 154px, $height: 52px);
        border-color: white;
        background-color: gray;
        color: white;

        &:hover {
          background-color: white;
          color: black;
        }
      }
    }

    &#info {
      gap: 44px;
      padding: $sectionPaddingY $pagePaddingX;

      .headline {
        gap: 20px;

        img.logo {
          height: 40px;
        }

        .divider {
          width: 150px;
        }

        .description {
          font-size: 20px;
        }
      }

      article {
        flex-direction: column;
        align-items: center;

        gap: 48px;

        .productWrapper {
          width: 80%;

          img {
            width: 100%;
            object-fit: contain;
          }
        }

        div {
          gap: 24px;

          p {
            font-size: 16px;
          }
        }
      }
    }

    &.pointHeadline {
      gap: 20px;

      .chip {
        padding: 4px 18px;

        font-size: 20px;
      }

      .title {
        font-size: 32px;
      }
    }

    &#viewMore {
      flex-direction: column;
      gap: 56px;

      padding: $sectionPaddingY $pagePaddingX;

      .general {
        gap: 100px;

        p {
          font-size: 32px;
        }
      }

      .cardWrapper {
        gap: 24px;
        flex-direction: column;
        align-items: flex-end;

        .viewMoreCard {
          border-radius: 12px;

          width: 260px;

          padding: 32px 32px 20px 32px;
          gap: 28px;

          img {
            &.logo {
              height: 32px;
            }
          }

          button {
            @include button-capsule($width: 152px, $height: 48px);
            font-size: 14px;
          }
        }
      }
    }
  }

  // MARK: Product Pages
  #medistep,
  #medistepM {
    section {
      &.points {
        padding: $sectionPaddingY $pagePaddingX;
        gap: 80px;

        .point {
          gap: 64px;

          &:nth-child(1) {
            div.content {
              flex-direction: column;
              gap: 24px;
            }
          }

          &:nth-child(2) {
            img {
              height: 254px;
            }
          }

          &:nth-child(3) {
            img {
              height: 420px;
            }
          }
        }
      }
    }
  }

  // MARK: About Company
  .page#aboutCompany {
    #pageContent {
      padding: 0px $pagePaddingX $sectionPaddingY $pagePaddingX;
      gap: $sectionGap;

      section {
        flex-direction: column;

        gap: 16px;

        aside {
          width: 280px;
          font-size: 24px;
        }

        &#background {
          article {
            gap: 20px;

            p {
              font-size: 18px;
            }

            figure {
              justify-content: center;
              gap: 24px;
              padding: 24px 0;
            }

            .refCard {
              width: 280px;
              min-height: 280px;

              .cardContent {
                padding: 24px 24px;
                gap: 16px;

                .title {
                  font-size: 20px;
                }

                .subTitle {
                  font-size: 17px;
                }

                div {
                  width: 44px;
                  height: 44px;

                  svg {
                    width: 18px;
                  }
                }
              }
            }
          }
        }

        &#missionVision {
          article {
            gap: 25px;

            .visionCard {
              padding: 32px 35px;
              width: 480px;
              min-height: 140px;

              p {
                font-size: 17px;
              }
            }
          }
        }

        &#history {
          article {
            gap: 48px;
          }

          .historyOfYear {
            gap: 48px;

            .year {
              font-size: 20px;
              line-height: 40px;
            }
          }

          .historyOfMonth {
            gap: 14px;

            p,
            li {
              font-size: 18px;
              line-height: 40px;
            }
          }
        }

        &#members {
          article {
            justify-content: center;
            gap: 32px 36px;

            .memberCard {
              width: 320px;
              min-height: 300px;
              padding: 28px 30px;

              gap: 14px;

              .name {
                font-size: 20px;
              }

              .description {
                gap: 4px;

                p {
                  font-size: 18px;
                }
              }
            }
          }
        }

        &#resource {
          article {
            .fileDownloader {
              gap: 24px;

              padding: 16px 0;

              p {
                font-size: 18px;
              }

              button {
                @include button-capsule($width: 150px, $height: 48px);

                min-width: 150px;
                min-height: 48px;
              }
            }
          }
        }
      }
    }
  }

  // MARK: About Usage
  .page#aboutUsage {
    #pageContent {
      #banners {
        .usageBanner {
          padding: 80px $pagePaddingX;
          gap: 24px;

          .title {
            font-size: 32px;
          }

          .descriptionWrapper {
            gap: 16px;

            p {
              font-size: 18px;
            }
          }

          .hashtagWrapper {
            margin-top: 20px;
            gap: 16px;

            p {
              padding: 8px 20px;
              font-size: 14px;
            }
          }
        }
      }

      #poc {
        gap: calc(0.75 * $sectionGap);
        padding: $sectionPaddingY $pagePaddingX;

        #bar {
          height: 72px;
        }

        article {
          &.title {
            gap: 10px;

            p {
              &:nth-of-type(1) {
                font-size: 32px;
              }
              &:nth-of-type(2) {
                font-size: 20px;
              }
            }
          }

          &.window {
            @include infinite-looper($contentGap: 50px, $duration: 60s);

            .pocCard {
              gap: 12px;

              img {
                width: 400px;
                height: 400px;
              }

              p {
                font-size: 14px;
              }
            }
          }
        }
      }

      #inquiry {
        gap: 48px;
        padding: $sectionPaddingY $pagePaddingX;

        div {
          gap: 24px;

          p {
            &:nth-of-type(1) {
              font-size: 32px;
            }

            &:nth-of-type(2) {
              font-size: 18px;
            }
          }
        }

        a {
          @include button-capsule($width: 200px, $height: 56px);
        }
      }
    }
  }

  // MARK: About News
  .page#aboutNews {
    #pageContent {
      section {
        &#newsCardGroup {
          gap: 48px 56px;
          padding: 0 $pagePaddingX $sectionPaddingY $pagePaddingX;

          .newsCard {
            width: 300px;
            min-height: 380px;

            border-radius: 10px;

            &:hover {
              transform: translateY(-16px);
            }

            .cardContent {
              > div {
                padding: 20px 32px;
                gap: 20px;

                p {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}
